import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Page from '../components/page/page'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      slug {
        current
      }
      title
      excerpt
      style {
        color
        textColor
        title
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      pages {
        _id
        title
        excerpt
        slug {
          current
        }
      }
      _rawBody
    }
  }
`

const PageTemplate = props => {
  const { data, errors } = props
  const page = data && data.page
  const parsedStyle = data && data.page.style
  let bgcolor
  let textColor

  // TODO: Refactor the following to better code..

  bgcolor = '#FFF3E9'
  textColor = '#092528'


  if (parsedStyle && parsedStyle.color) {
    bgcolor = parsedStyle.color
  }

  if (parsedStyle && parsedStyle.textColor) {
    textColor = parsedStyle.textColor
  }

  return (
    <Layout
      backgroundColor = {bgcolor}
      textColor= {textColor}
    
    >
      {errors && <SEO title='GraphQL Error' />}
      {page && <SEO title={page.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {page && <Page {...page} />}
    </Layout>
  )
}

export default PageTemplate
