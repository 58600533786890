import React from 'react'
import BlockTextSans from '../block-content-sans'
import Container from '../container'
import ContactDemo from '../contact/contact-demo'
import PagePreviewGrid from './page-preview-grid'
import PageIntro from './page-intro2'
import * as styles from './page-css/page.module.css'

function  Page (props) {
  const { _rawBody, title, style, mainImage, excerpt } = props
  const parsedStyle = style
  let bgcolor
  let textColor
  // TODO: Refactor the following to better code..
  bgcolor = '#FFF3E9'
  textColor = '#092528'

  if (parsedStyle && parsedStyle.color) {
    bgcolor = parsedStyle.color
  }

  if (parsedStyle && parsedStyle.textColor) {
    textColor = parsedStyle.textColor
  }

  return (
    <article className={styles.root}>

      <PageIntro
        title={title}
        excerpt={excerpt}
        centered={false}
        backgroundColor = {bgcolor}
        textColor = {textColor}
        mainImage = {mainImage}
      /> 

      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            {_rawBody && <BlockTextSans blocks={_rawBody || []} />}
          </div>
        </div>

        <div>
          {props.pages && (
            <PagePreviewGrid {...props}
              itemColor='white'
              title=''
            /> 
          )}

        </div>
        <ContactDemo title={'Get a free walkthough of the platform'} subtitle={'Book a demo'} />

      </Container>
    </article>
  )
}

export default Page
