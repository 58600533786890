// extracted by mini-css-extract-plugin
export var categories = "page-module--categories--XUzdq";
export var categoriesHeadline = "page-module--categoriesHeadline--NwFE8 typography-module--base--FphVn";
export var grid = "page-module--grid--ZgZCS";
export var gridRelated = "page-module--gridRelated--Kh8Tz";
export var itemBlock = "page-module--itemBlock--vBKi8";
export var mainContent = "page-module--mainContent--zZ3K-";
export var mainImage = "page-module--mainImage--xQx18";
export var metaContent = "page-module--metaContent--w10Jl";
export var publishedAt = "page-module--publishedAt--qlVja typography-module--small--KHf9L";
export var relatedProjects = "page-module--relatedProjects--OgJo5";
export var relatedProjectsHeadline = "page-module--relatedProjectsHeadline--8hc7F typography-module--base--FphVn";
export var root = "page-module--root--OqaJX";
export var title = "page-module--title--7KWb- typography-module--responsiveTitle1--0t0jA";